var ThronService = (function ($) {
    'use strict';
    var sendMail = function (contentId, documentSection) {     
        return $.ajax({
            type: 'POST',
            url: '/Document/SendMail',
            data: JSON.stringify({ contentId: contentId, documentSection: documentSection }),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json'
        });
    }

    var documentUrl = function (contentId, documentSection) {
        return $.ajax({
            type: 'POST',
            url: '/Document/DocumentUrl',
            data: JSON.stringify({ contentId: contentId, documentSection: documentSection }),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json'
        });
    }

    return {
        sendMail: sendMail,
        documentUrl: documentUrl,
    }

})(jQuery);