var RatingService = (function (t) {
    'use strict';
    var rating, stars;
    var rating = document.querySelector('.rate-this');
    if(rating) stars = rating.querySelectorAll('[data-rate]');

    function onstar(evt) {
        if (rating.classList.contains('rated-this')) return;
        var r = evt.target.getAttribute('data-rate');
        unrate();
        rating.classList.add('rate-this-' + r);
    }

    function leave(evt) {
        if (rating.classList.contains('rated-this')) return;
        unrate();
    }

    function unrate() {
        rating.classList.remove('rate-this-1');
        rating.classList.remove('rate-this-2');
        rating.classList.remove('rate-this-3');
        rating.classList.remove('rate-this-4');
        rating.classList.remove('rate-this-5');
        rating.classList.remove('rated-this-1');
        rating.classList.remove('rated-this-2');
        rating.classList.remove('rated-this-3');
        rating.classList.remove('rated-this-4');
        rating.classList.remove('rated-this-5');
    }

    function rate(evt) {
        var ID = rating.getAttribute('data-content-id');
        var r = evt.target.getAttribute('data-rate');
        unrate();
        rating.classList.add('rated-this');
        rating.classList.add('rated-this-' + r);

        var req = new XMLHttpRequest();

        req.open('POST', '/News/SetRating?ID=' + ID + '&rating=' + r);
        req.send();

        req.onreadystatechange = function () {
            if (req.readyState == 4) {
                feedback(req.response);
            }
        }

        function feedback(response) {
            var res = JSON.parse(response);
            if (res.success) {
                t.success("Grazie per aver espresso il tuo gradimento");
                disable();
            } else {
                t.error("Hai già votato questo contenuto");
                disable();
                unrate();
            }
        }
    }

    function init() {
        for (var i = 0; i < stars.length; i++) {
            stars[i].addEventListener('mouseover', onstar);
            stars[i].addEventListener('click', rate);
        }
        rating.addEventListener('mouseleave', leave);
    }

    function disable() {
        for (var i = 0; i < stars.length; i++) {
            stars[i].removeEventListener('mouseover', onstar);
            stars[i].removeEventListener('click', rate);
        }
        rating.removeEventListener('mouseleave', leave);
        rating.classList.add('-disabled');
    }

    return {
        init: init,
        disable: disable
    }

})(toastr);