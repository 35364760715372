function XUICarousel(id) {
    this.initialize(id);
}

XUICarousel.prototype = (function () {
    var __PROTOTYPE = {};

    Object.defineProperties(__PROTOTYPE, {
        'container': {
            writable: true
        },
        'items': {
            writable: true
        }
    });

    function getItems() {
        var items = [];
        var children = this.container.querySelector('ul').childNodes;
        for (var i = 0; i < children.length; i++) {
            if (children[i].nodeName === 'LI') items.push(children[i]);
        }
        return items;
    }

    function getNextItem(element) {
        var element = element;
        var target = element;
        while (element) {
            element = element.nextSibling;
            if (!element) return getItems.apply(this)[0];
            if (element && element.nodeName == target.nodeName) return element;
        }
    }

    function getPrevItem(element) {
        var element = element;
        var target = element;
        while (element) {
            element = element.previousSibling;
            if (!element) {
                var list = getItems.apply(this);
                return list[list.length - 1];
            }
            if (element.nodeName == target.nodeName) return element;
        }
    }

    function beginSwipe(event) {
        this.swipeStart = event;
    }

    function updateSwipe(event) {
        this.swipeEnd = event;
    }

    function endSwipe(event) {
        if (this.swipeStart && this.swipeEnd) {
            var newEvent;
            var startX = this.swipeStart.pageX ? this.swipeStart.pageX : this.swipeStart.touches[0].pageX;
            var startY = this.swipeStart.pageY ? this.swipeStart.pageY : this.swipeStart.touches[0].pageY;
            var endX = this.swipeEnd.pageX ? this.swipeEnd.pageX : this.swipeEnd.touches[0].pageX;
            var endY = this.swipeEnd.pageY ? this.swipeEnd.pageY : this.swipeEnd.touches[0].pageY;
            var deltaT = parseInt(this.swipeEnd.timeStamp) - parseInt(this.swipeStart.timeStamp);
            var deltaX = startX - endX;
            var deltaY = startY - endY;
            if (deltaT > 450) {
                this.swipeStart = null;
                this.swipeEnd = null;
                return;
            }
            if (Math.abs(deltaX) <= 50) {
                this.swipeStart = null;
                this.swipeEnd = null;
                return;
            }
            if (Math.tan(Math.abs(deltaY) / Math.abs(deltaX)) > .75 || Math.tan(Math.abs(deltaY) / Math.abs(deltaX)) < -.75) {
                this.swipeStart = null;
                this.swipeEnd = null;
                return;
            }
            if (deltaX > 0) {
                try {
                    newEvent = new Event('swipeleft', { 'bubbles': true, 'cancelable': false });
                }
                catch (err) {
                    newEvent = document.createEvent('Event');
                    newEvent.initEvent('swipeleft', true, false);
                }
            } else {
                try {
                    newEvent = new Event('swiperight', { 'bubbles': true, 'cancelable': false });
                }
                catch (err) {
                    newEvent = document.createEvent('Event');
                    newEvent.initEvent('swipeleft', true, false);
                }
            }
            this.container.dispatchEvent(newEvent);
        }
        this.swipeStart = null;
        this.swipeEnd = null;
    }

    function endTransition(event) {
        if (event.type != 'transitionend') return;
        if (event.target.nodeName != 'LI') return;

        if (event.target.classList.contains('-out')) {
            event.target.classList.remove('-current');
            event.target.classList.remove('-out');
        }
        if (event.target.classList.contains('-in')) {
            event.target.classList.add('-current');
            event.target.classList.remove('-in');
        }
        if (this.container.querySelectorAll('li.-in').length == 0 && this.container.querySelectorAll('li.-out').length == 0) {
            this.container.classList.remove('-transitioning');
            var customEvent;
            try {
                customEvent = new Event('XUIslideDone', { 'bubbles': true, 'cancelable': false });
            }
            catch (err) {
                customEvent = document.createEvent('Event');
                customEvent.initEvent('XUIslideDone', true, false);
            }
            this.container.dispatchEvent(customEvent);
            this.update();
        }
    }

    function createTicker() {
        var ticker = document.createElement('span');
        ticker.classList.add('xui-carousel-ticker');
        ticker.addEventListener('transitionend', this.next.bind(this));
        this.container.appendChild(ticker);
    }

    function createNavigation() {
        this.prevButton = this.container.querySelector('[data-prev-item]');
        this.nextButton = this.container.querySelector('[data-next-item]');
        if (!this.prevButton) {
            var button = document.createElement('a');
            button.setAttribute('data-prev-item', '');
            this.prevButton = button;
            this.container.appendChild(button);
        }
        if (!this.nextButton) {
            var button = document.createElement('a');
            button.setAttribute('data-next-item', '');
            this.nextButton = button;
            this.container.appendChild(button);
        }
        this.prevButton.addEventListener('click', this.prev.bind(this));
        this.nextButton.addEventListener('click', this.next.bind(this));
        return this;
    }

    function autoplayOnLoad() {
        var isVisible;
        window.addEventListener('load', onload.bind(this));
        window.addEventListener('scroll', onscroll.bind(this));

        function onload() {
            isVisible = Math.max(Math.min(parseInt(this.container.getBoundingClientRect().top) + parseInt(this.container.clientHeight) * 0.4, 1), 0);
            if (isVisible) this.play();
        }

        function onscroll() {
            var inview = Math.max(Math.min(parseInt(this.container.getBoundingClientRect().top) + parseInt(this.container.clientHeight) * 0.4, 1), 0);
            if (!inview && isVisible) {
                this.pause();
                try {
                    newEvent = new Event('XUICarouselViewportLeave', { 'bubbles': true, 'cancelable': false });
                }
                catch (err) {
                    newEvent = document.createEvent('Event');
                    newEvent.initEvent('XUICarouselViewportLeave', true, false);
                }
                document.dispatchEvent(newEvent);
            } else if (inview && !isVisible) {
                try {
                    newEvent = new Event('XUICarouselViewportEnter', { 'bubbles': true, 'cancelable': false });
                }
                catch (err) {
                    newEvent = document.createEvent('Event');
                    newEvent.initEvent('XUICarouselViewportEnter', true, false);
                }
                document.dispatchEvent(newEvent);
                this.play();
            }
            isVisible = inview;
        }

    }

    Object.defineProperty(__PROTOTYPE, 'initialize', {
        value: function (id) {
            if (this.ready()) return this;
            this.container = document.getElementById(id);
            if (!this.container) return;
            this.items = getItems.apply(this);;
            if (this.items.length == 0) return;
            if (this.items.length == 1) {
                this.items[0].classList.add('-current');
                return;
            }

            if (this.container.getAttribute('data-xui-add-pager')) this.addPager();
            if (this.items.length == 2) {
                var list = this.container.querySelector('ul');
                for (var i = 0; i < this.items.length; i++) {
                    list.appendChild(this.items[i].cloneNode(true));
                }
                this.items = getItems.apply(this);;
            }

            if (this.container.getAttribute('data-xui-autoplay')) {
                autoplayOnLoad.apply(this);
            }

            this.update();

            for (var j = 0; j < this.items.length; j++) {
                this.items[j].addEventListener('transitionend', endTransition.bind(this));
            }
            this.swipeStart = null;
            this.swipeEnd = null;
            this.container.addEventListener('touchstart', beginSwipe.bind(this));
            this.container.addEventListener('touchmove', updateSwipe.bind(this), { passive: true });
            this.container.addEventListener('touchend', endSwipe.bind(this));
            this.container.addEventListener('swipeleft', this.next.bind(this));
            this.container.addEventListener('swiperight', this.prev.bind(this));
            createNavigation.apply(this);
            createTicker.apply(this);
            try {
                customEvent = new Event('XUICarouselReady', { 'bubbles': true, 'cancelable': false });
            }
            catch (err) {
                customEvent = document.createEvent('Event');
                customEvent.initEvent('XUICarouselReady', true, false);
            }
            this.container.dispatchEvent(customEvent);
        }
    });

    Object.defineProperty(__PROTOTYPE, 'update', {
        value: function () {
            if (!this.ready()) return;
            if (this.container.querySelector('li.-prev')) this.container.querySelector('li.-prev').classList.remove('-prev');
            if (this.container.querySelector('li.-next')) this.container.querySelector('li.-next').classList.remove('-next');
            var current = this.container.querySelector('li.-current');
            if (!current) current = this.items[0];
            current.classList.add('-current');
            getNextItem.apply(this, [current]).classList.add('-next');
            getPrevItem.apply(this, [current]).classList.add('-prev');
            if (this.deferredNext) {
                this.deferredNext = false;
                this.next.bind(this);
            }
            if (this.deferredPrev) {
                this.deferredPrev = false;
                this.prev.bind(this);
            }
            return this;
        }
    });

    Object.defineProperty(__PROTOTYPE, 'addPager', {
        value: function () {
            if (!this.ready()) return;
            this.pager = document.createElement('div');
            this.pager.setAttribute('data-xui-carousel-pager', this.container.getAttribute('id'));
            var ul = document.createElement('ul');
            this.pager.appendChild(ul);
            this.container.appendChild(this.pager);
            for (var i = 0; i < this.items.length; i++) {
                var pagerItem = document.createElement('li');
                pagerItem.innerText = i + 1;
                pagerItem.setAttribute('data-xui-carousel-index', i);
                this.pager.querySelector('ul').appendChild(pagerItem);
                pagerItem.addEventListener('click', navigatePager.bind(this))
            }
            this.container.addEventListener('XUIslideStart', updatePager.bind(this));
            this.container.addEventListener('XUICarouselReady', initPager.bind(this));

            function navigatePager(evt) {
                var index = parseInt(evt.target.getAttribute('data-xui-carousel-index'));
                if (!isNaN(index)) this.goto(index);
            }

            function initPager(event) {
                if (this.pager.querySelector('li.-current')) {
                    this.pager.querySelector('li.-current').classList.remove('-current');
                }
                var slideIndex = this.items.indexOf(this.container.querySelector('li.-current'));
                if (slideIndex < this.pager.querySelectorAll('li[data-xui-carousel-index]').length) {
                    this.pager.querySelector('li[data-xui-carousel-index="' + slideIndex + '"]').classList.add('-current');
                } else {
                    this.pager.querySelector('li[data-xui-carousel-index="' + (slideIndex - 2) + '"]').classList.add('-current');
                }
            }


            function updatePager(event) {
                if (this.pager.querySelector('li.-current')) {
                    this.pager.querySelector('li.-current').classList.remove('-current');
                }
                var slideIndex = this.items.indexOf(this.container.querySelector('li.-in'));
                if (slideIndex < this.pager.querySelectorAll('li[data-xui-carousel-index]').length) {
                    this.pager.querySelector('li[data-xui-carousel-index="' + slideIndex + '"]').classList.add('-current');
                } else {
                    this.pager.querySelector('li[data-xui-carousel-index="' + (slideIndex - 2) + '"]').classList.add('-current');
                }
            }

        }
    })

    Object.defineProperty(__PROTOTYPE, 'prev', {
        value: function () {
            if (!this.ready()) return;
            if (this.container.classList.contains('-transitioning')) return;
            this.container.classList.add('-transitioning');
            var current = this.container.querySelector('li.-current');
            var prev = this.container.querySelector('li.-prev');
            current.classList.add('-out');
            prev.classList.add('-in');
            try {
                customEvent = new Event('XUIslideStart', { 'bubbles': true, 'cancelable': false });
            }
            catch (err) {
                customEvent = document.createEvent('Event');
                customEvent.initEvent('XUIslideStart', true, false);
            }
            this.container.dispatchEvent(customEvent);
        }
    });

    Object.defineProperty(__PROTOTYPE, 'next', {
        value: function () {
            if (!this.ready()) return;
            if (this.container.classList.contains('-transitioning')) return;
            this.container.classList.add('-transitioning');
            var current = this.container.querySelector('li.-current');
            var next = this.container.querySelector('li.-next');
            current.classList.add('-out');
            next.classList.add('-in');
            try {
                customEvent = new Event('XUIslideStart', { 'bubbles': true, 'cancelable': false });
            }
            catch (err) {
                customEvent = document.createEvent('Event');
                customEvent.initEvent('XUIslideStart', true, false);
            }
            this.container.dispatchEvent(customEvent);
        }
    });

    Object.defineProperty(__PROTOTYPE, 'goto', {
        value: function (index) {
            if (!this.ready()) return;
            if (this.container.classList.contains('-transitioning')) return;
            if (!this.items[index]) return;
            var current = this.container.querySelector('li.-current');
            if (index == this.items.indexOf(current)) return;
            this.container.classList.add('-transitioning');
            var prev = this.container.querySelector('li.-prev');
            var next = this.container.querySelector('li.-next');
            current.classList.add('-out');
            prev.classList.remove('-prev');
            next.classList.remove('-next');
            this.items[index].classList.add('-next');
            this.items[index].classList.add('-in');
            try {
                customEvent = new Event('XUIslideStart', { 'bubbles': true, 'cancelable': false });
            }
            catch (err) {
                customEvent = document.createEvent('Event');
                customEvent.initEvent('XUIslideStart', true, false);
            }
            this.container.dispatchEvent(customEvent);
        }
    });

    Object.defineProperty(__PROTOTYPE, 'play', {
        value: function () {
            if (!this.ready()) return;
            this.container.classList.add('-playing');
        }
    });

    Object.defineProperty(__PROTOTYPE, 'pause', {
        value: function () {
            if (!this.ready()) return;
            this.container.classList.remove('-playing');
        }
    });

    Object.defineProperty(__PROTOTYPE, 'ready', {
        value: function () {
            if (!this.container) return false;
            if (!this.items) return false
            if (this.items.length <= 1) return false;
            return true;
        }
    });

    return __PROTOTYPE;
})();
