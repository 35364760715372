function ListFilter(id, param) {
    'use strict';
    this.initialize(id, param);
}

ListFilter.prototype = (function (document) {
    'use strict';
    var _PROTOTYPE = {};

    function filter(list, param) {
        var out = list.querySelectorAll('li:not([data-filter-param="' + param + '"])');
        for (var i = 0; i < out.length; i++) {
            out[i].classList.add('-filter-out');
        }
    }

    function unfilter(list) {
        var items = list.querySelectorAll('li')
        for (var i = 0; i < items.length; i++) {
            items[i].classList.remove('-filter-out');
        }
    }

    Object.defineProperties(_PROTOTYPE, {
        select: {
            writable: true,
            configurable: true,
            value: null
        },
        initialize: {
            value: function (id, param) {
                this.select = document.getElementById(id);
                if (!this.select || this.select.tagName !== 'SELECT') return;
                this.list = document.getElementById(this.select.getAttribute('data-filter-list'));
                if (!this.list || this.list.tagName !== 'UL') return;
                this.select.addEventListener('change', this.onselectchange.bind(this));
                if (param) {                  
                    unfilter(this.list);
                    filter(this.list, param);
                }
            }
        },
        ready: {
            value: function () {
                return this.select != null && this.list != null;
            }
        },
        onselectchange: {
            value : function(evt) {
                unfilter(this.list);
                if (evt.target.value != '') filter(this.list, evt.target.value)
            }
        }
    });

    return _PROTOTYPE;
})(document);