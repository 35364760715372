function FormActions(formSelector, options) {
    this.initialize(formSelector, options);
}

FormActions.prototype = (function ($, t) {
    'use strict';

    t.options = {
        closeButton: false,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: "toast-bottom-right",
        preventDuplicates: false,
        onclick: null,
        showDuration: "300",
        hideDuration: "1000",
        timeOut: "3000",
        extendedTimeOut: "1000",
        showMethod: "fadeIn",
        hideMethod: "fadeOut"
    }

    var _P = {};

    var _displayForm = function () {
        this.form.show();
        if(this.content.length) this.content.show();
        if (this.loader.length) this.loader.hide();
        if (this.alert.length) this.alert.hide();
        if (typeof (this.ondisplay) === 'function') this.ondisplay();
    }

    var _feedback = function (type) {
        switch (type) {
            case 'error':
                return t.error('Si è verificato un errore');
            default:
                return t.success('Impostazioni salvate');
        }
    }

    var _bindSubmitOnChange = function () {
        var b = this.form.find('input[type=checkbox], input[type=radio]');
        var f = this.form;
        b.bind('change', function (evt) {
            f.submit();
        });
    }

    var _confirmSubmit = function () {
        this.confirmed = true;
        this.form.submit();
    }

    var _cancelSubmit = function() {
        this.confirmed = false;
        if(this.content.length) this.content.show();
        if (this.alert.length) this.alert.hide();
        if (typeof(this.oncancel) === 'function') this.oncancel.apply(this);
    }

    Object.defineProperties(_P, {

        'form': {
            value: null,
            writable: true,
            configurable: true
        },

        'content': {
            value: null,
            writable: true,
            configurable: true
        },

        'loader': {
            value: null,
            writable: true,
            configurable: true
        },

        'submitOnChange': {
            value: false,
            writable: true
        },

        'confirmSubmit': {
            value: false,
            writable: true
        },

        'confirmed': {
            value: false,
            writable: true
        }

    });

    Object.defineProperty(_P, 'initialize', {
        value: function (formSelector, options) {
            this.form = $(formSelector);
            if (!this.form.length) return;
            this.content = this.form.find('[data-form-content]');
            this.loader = this.form.find('[data-form-loader]');
            this.alert = this.form.find('[data-form-alert]');
            this.confirm = this.form.find('[data-confirm-form-submit]');
            this.cancel = this.form.find('[data-cancel-form-submit]');

            if (options && options.submitOnChange) this.submitOnChange = true;
            if (options && options.confirmSubmit) this.confirmSubmit = options.confirmSubmit;
            if (options && typeof (options.onloading) === 'function') this.onloading = options.onloading;
            if (options && typeof (options.onupdate) === 'function') this.onupdate = options.onupdate;
            if (options && typeof (options.oncancel) === 'function') this.oncancel = options.oncancel;

            if (this.submitOnChange) _bindSubmitOnChange.apply(this);
            if (this.confirm.length) this.confirm.bind('click', _confirmSubmit.bind(this));
            if (this.cancel.length) this.cancel.bind('click', _cancelSubmit.bind(this));

            this.askForConfirmation = this.askForConfirmation.bind(this);

            window.setTimeout(_displayForm.bind(this), 1000);
        }
    });

    Object.defineProperty(_P, 'loading', {
        value: function (evt) {
            if (this.content.length) this.content.hide();
            if (this.loader.length) this.loader.show();
            if (typeof (this.onloading) === 'function') this.onloading();
        }
    });

    Object.defineProperty(_P, 'update', {
        value: function () {
            this.confirmed = false;
            if (this.submitOnChange) _bindSubmitOnChange.apply(this);
            window.setTimeout(_feedback, 750);
            window.setTimeout(_displayForm.bind(this), 1000);
            if (typeof (this.onupdate) === 'function') this.onupdate();
        },

    });

    Object.defineProperty(_P, 'askForConfirmation', {
        value: function () {
            if (typeof(this.confirmSubmit) === 'function' && this.confirmSubmit()) {
                this.form.find('[data-form-content]').hide();
                this.form.find('[data-form-alert]').show();
                return this.confirmed;
            } else {
                return true;
            }
        },
        writable: true
    });

    return _P;


})(jQuery, toastr);
