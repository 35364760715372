(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('PromoModal', ['ModalWindow', 'docCookies'], factory);
    } else {
        // Browser globals
        root.PromoModal = factory(root.ModalWindow, root.docCookies);
    }
}(typeof self !== 'undefined' ? self : this, function (Modal, cookies) {
        'use strict';

        if (typeof(Modal) !== 'function') {
            throw new ReferenceError('ModalWindow is required by PromoModal');
        }

        if (typeof(cookies) === 'undefined') {
            throw new ReferenceError('docCookies (https://github.com/madmurphy/cookies.js) is required by PromoModal');
        }

        function Promo(element) {
            if (typeof (element) === 'undefined') {
                return;
            }

            this.container = element;
            this.modal = new Modal(element);

            this.open = this.open.bind(this);
            this.close = this.close.bind(this);

            this.dismiss = this.container.querySelectorAll('[data-dismiss]');
            Array.prototype.forEach.apply(this.dismiss, [function (el) {
                el.addEventListener('click', this.close);
            }, this]);

            this.cookieName = this.container.getAttribute('data-name') || this.container.id + '_modal_viewed';

            // 0 = session (session cookie is set), 
            // 1 = once (cookie expires in year 9999), 
            // 2 = yearly (cookie expires 365 days from now, at midnight),
            // 3 = monthly (cookie expires 30 days from now, at midnight),
            // 4 = weekly (cookie expires seven days from now, at midnight),
            // 5 = daily (cookie expires tomorrow at midnight),
            // 6 = always (no cookie is set)
            this.behaviour = parseInt(this.container.getAttribute('data-behaviour')) || 0;

            this.expires = this.container.getAttribute('data-expires');

            window.addEventListener('load', this.open)
        }

        Promo.prototype = {
            open: function () {
                if (cookies.getItem(this.cookieName)) {
                    return
                }

                switch (this.behaviour) {
                    case 6:
                        // display on each and every page load, set no cookie
                        break;
                    case 5:
                        // cookie expires tomorrow at midnight
                        var date = new Date();
                        date.setDate(date.getDate() + 1);
                        date.setHours(0, 0, 0, 0);
                        cookies.setItem(this.cookieName, true, date);
                        break;
                    case 4:
                        // cookie expires in 7 days, at midnight
                        var date = new Date();
                        date.setDate(date.getDate() + 8);
                        date.setHours(0, 0, 0, 0);
                        cookies.setItem(this.cookieName, true, date);
                        break;
                    case 3:
                        // cookie expires in 30 days, at midnight
                        var date = new Date();
                        date.setDate(date.getDate() + 31);
                        date.setHours(0, 0, 0, 0);
                        cookies.setItem(this.cookieName, true, date);
                        break;
                    case 2:
                        // cookie expires in 365 days, at midnight
                        var date = new Date();
                        date.setDate(date.getDate() + 366);
                        date.setHours(0, 0, 0, 0);
                        cookies.setItem(this.cookieName, true, date);
                        break;
                    case 1:
                        // cookie expires in year 9999
                        cookies.setItem(this.cookieName, true, Infinity);
                        break;
                    case 0:
                    default:
                        // set session cookie
                        cookies.setItem(this.cookieName, true);
                        break;
                }

                this.modal.open();

            },
            close: function () {
                this.modal.close();
            }
        }

        return Promo
}));