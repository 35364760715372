var DocumentService = (function ($) {
    'use strict';
    var sendMail = function(progr, docid, doctype) {
        return $.ajax({
            type: 'POST',
            url: '/DocumentViewer/SendMail',
            data: JSON.stringify({ progr: progr, docid: docid, doctype: doctype }),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json'
        });
    }

    var documentUrl = function (prog, id, doctype) {
        return $.ajax({
            type: 'POST',
            url: '/DocumentViewer/DocumentUrl',
            data: JSON.stringify({ progr: prog, docid: id, doctype: doctype }),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json'
        });
    }

    return {
        sendMail: sendMail,
        documentUrl: documentUrl,
        DocTypePaySplip: 'ESTRATTO',
        DocTypePayCu: 'CU',
    }

})(jQuery);